import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0];

export const dictionary = {
		"/[[lang=lang]]/(app)/(centered)": [11,[2,3]],
		"/[[lang=lang]]/(app)/(centered)/changelog": [12,[2,3]],
		"/[[lang=lang]]/(app)/(redirects)/characters": [~28,[2]],
		"/[[lang=lang]]/(app)/(centered)/database": [~13,[2,3]],
		"/[[lang=lang]]/(app)/(centered)/database/characters": [14,[2,3,4]],
		"/[[lang=lang]]/(app)/(centered)/database/characters/[id=integer]": [15,[2,3,4]],
		"/[[lang=lang]]/(app)/(centered)/database/items": [16,[2,3,5]],
		"/[[lang=lang]]/(app)/(centered)/database/items/[id=integer]": [17,[2,3,5]],
		"/[[lang=lang]]/(app)/(centered)/leaderboard": [~18,[2,3,6]],
		"/[[lang=lang]]/(app)/(centered)/leaderboard/artisan": [19,[2,3,6,7]],
		"/[[lang=lang]]/(app)/(centered)/leaderboard/artisan/[character=integer]": [20,[2,3,6,7]],
		"/[[lang=lang]]/(app)/(centered)/leaderboard/rp": [21,[2,3,6]],
		"/[[lang=lang]]/(app)/(centered)/matches/[match=integer]": [22,[2,3]],
		"/[[lang=lang]]/(app)/(centered)/players/[name]": [23,[2,3,,9],[,,8]],
		"/[[lang=lang]]/(app)/(centered)/players/[name]/activities": [24,[2,3,,9],[,,8]],
		"/[[lang=lang]]/(app)/(centered)/players/[name]/characters": [25,[2,3,,9],[,,8]],
		"/[[lang=lang]]/(app)/(fullpage)/simulator": [~26,[2,10]],
		"/[[lang=lang]]/(app)/(fullpage)/simulator/route": [27,[2,10]],
		"/[[lang=lang]]/(widget)/widget": [29]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';